/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-repeat {
  background-repeat: repeat
}

.border-collapse {
  border-collapse: collapse
}

.border {
  border-width: 1px
}

.border-t {
  border-top-width: 1px
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-none {
  flex: none
}

.font-sans {
  font-family: "Whitney A","Whitney B"
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.font-bold {
  font-weight: 700
}

.h-10 {
  height: 2.5rem
}

.h-auto {
  height: auto
}

.text-xs {
  font-size: 0.75rem
}

.text-sm {
  font-size: 0.875rem
}

.text-lg {
  font-size: 1.125rem
}

.text-xl {
  font-size: 1.25rem
}

.text-5xl {
  font-size: 3rem
}

.leading-tight {
  line-height: 1.25
}

.leading-normal {
  line-height: 1.5
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mb-20 {
  margin-bottom: 5rem
}

.-mt-2 {
  margin-top: -0.5rem
}

.-mt-3 {
  margin-top: -0.75rem
}

.max-w-xl {
  max-width: 36rem
}

.opacity-50 {
  opacity: 0.5
}

.overflow-hidden {
  overflow: hidden
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-8 {
  padding: 2rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.pb-0 {
  padding-bottom: 0
}

.pt-2 {
  padding-top: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-8 {
  padding-top: 2rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pl-8 {
  padding-left: 2rem
}

.pt-12 {
  padding-top: 3rem
}

.pr-12 {
  padding-right: 3rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-20 {
  padding-bottom: 5rem
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0
}

.left-0 {
  left: 0
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.underline {
  text-decoration: underline
}

.tracking-wide {
  letter-spacing: 0.025em
}

.visible {
  visibility: visible
}

.whitespace-normal {
  white-space: normal
}

.w-10 {
  width: 2.5rem
}

.w-12 {
  width: 3rem
}

.w-40 {
  width: 10rem
}

.w-1\/2 {
  width: 50%
}

.w-full {
  width: 100%
}

.z-50 {
  z-index: 50
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

/**
 * Include vendor css.
 *
 */

/**
 * vendor.css
 *
 * All vendor CSS is imported here.
 *
 */

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}
