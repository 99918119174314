/**
 * This injects any component classes registered by plugins.
 *
 */

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

/**
 * components/global.css
 *
 * Project-wide styles
 *
 */

.levi { font-family: "Leviathan A","Leviathan B";
}

.snipcart-checkout { display: block; width: 100%; height: 25px;
}

img { height: auto;
}

.snipcart-add-item.btn { background: rgba(0,0,0,0.5);
}

p { padding: 0 0 1em 0;
}

h2, h1, h3 { margin: 0.85em 0; font-weight: bold;
}

/**
 * components/typography.css
 * 
 * Typography rules.
 *
 */

/**
 * components/webfonts.css
 * 
 * Project webfonts.
 *
 */

/**
 * Include styles for individual pages
 *
 */

/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}
